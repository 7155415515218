import { useState, useRef, useEffect } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

function MapSelector({ latitude, longitude, handleInputChange = undefined }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const marker = useRef(null);
  const API_KEY = MAPY_API_KEY;

  const zoom = 7;
  const coords = [longitude, latitude];

  const [editMode, setEditMode] = useState(false);

  const initMap = () => {
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      center: longitude ? coords : [14.8981184, 49.8729317],
      zoom,
      style: {
        version: 8,
        sources: {
          // style for map tiles
          'basic-tiles': {
            type: 'raster',
            url: `https://api.mapy.cz/v1/maptiles/basic/tiles.json?apikey=${API_KEY}`,
            tileSize: 256,
          },
        },
        layers: [
          {
            id: 'tiles',
            type: 'raster',
            source: 'basic-tiles',
          },
        ],
      },
    });

    map.current.on('load', () => {
      if (latitude) {
        marker.current = new maplibregl.Marker()
          .setLngLat(coords)
          .addTo(map.current);
      }
    });
  };

  useEffect(() => {
    if (map.current) {
      map.current.setCenter(coords);
      if (marker.current) {
        marker.current.setLngLat(coords);
      } else if (latitude) {
        // Add marker
        marker.current = new maplibregl.Marker()
          .setLngLat(coords)
          .addTo(map.current);
      }
      return; // stops map from initializing more than once
    }
    if (!mapContainer.current) return;

    initMap();
  }, [API_KEY, latitude, longitude]);

  useEffect(() => {
    if (!map.current) return;

    if (editMode) {
      // Change the cursor to "crosshair"
      map.current.getCanvas().style.cursor = 'crosshair';

      // Add click listener to map
      const onMapClick = (e) => {
        const { lng, lat } = e.lngLat;

        // Update the marker position
        if (!marker.current) {
          marker.current = new maplibregl.Marker()
            .setLngLat([lng, lat])
            .addTo(map.current);
        } else {
          marker.current.setLngLat([lng, lat]);
        }

        // Update parents - ToDo - through props next time
        var reactComponent =
          window.contractForm || window.customerForm || window.userForm;
        if (reactComponent) {
          if (window.userForm) {
            reactComponent.setState({
              user: Object.assign({}, reactComponent.state.user, {
                latitude: lat,
                longitude: lng,
              }),
            });
          } else {
            let uuid =
              reactComponent.state.activePlaceUUID ||
              reactComponent.state.contract.place_uuid;
            let places = reactComponent.state.customer.places.map((i) => {
              if (i.uuid == uuid) {
                return Object.assign(i, {
                  latitude: lat,
                  longitude: lng,
                });
              } else {
                return i;
              }
            });
            reactComponent.setState({
              latitude: lat,
              longitude: lng,
              customer: Object.assign(reactComponent.state.customer, {
                places: places,
              }),
            });
          }
        } else if (handleInputChange) {
          handleInputChange({
            latitude: lat,
            longitude: lng,
          });
        } else {
          let wrapper = document.querySelector('.address-wrapper');
          if (wrapper) {
            wrapper.querySelector('input.latitude').value = lat;
            wrapper.querySelector('input.longitude').value = lng;
          }
        }
      };

      map.current.on('click', onMapClick);

      // Cleanup: Remove the listener when exiting edit mode
      return () => {
        map.current.off('click', onMapClick);
        map.current.getCanvas().style.cursor = ''; // Reset the cursor
      };
    } else {
      // Reset the cursor when not in edit mode
      map.current.getCanvas().style.cursor = '';
    }
  }, [editMode]);

  return (
    <>
      <div className='map-selector-wrap'>
        <div className='map-selector' ref={mapContainer}></div>
      </div>
      <div className='text-center'>
        <button
          type='button'
          className={`btn btn-outline-secondary marker-button ${
            editMode ? 'active' : ''
          }`}
          onClick={() => setEditMode(!editMode)}
        >
          {editMode
            ? 'Ukončit úpravy polohy'
            : 'Upřesnit polohu kliknutím do mapy'}
        </button>
      </div>
    </>
  );
}

export default MapSelector;
