class Whisper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      results: [],
      index: -1,
      currentRequest: null,
      loading: false,
      timeout: null,
    };
    this.currentRequest = null;
  }
  UNSAFE_componentWillMount() {}
  componentDidMount() {
    let _this = this;
    this.props.input.addEventListener('keyup', function (e) {
      switch (e.keyCode) {
        case 27:
          _this.setState({ query: '', index: -1 });
          break;
        case 40:
          // Arrow Down
          if (_this.state.results.length - 1 > _this.state.index)
            _this.setState({ index: _this.state.index + 1 });
          if (_this.state.query == '')
            _this.setState({ query: _this.props.input.value });
          break;
        case 38:
          // Arrow Up
          if (_this.state.index > 0)
            _this.setState({ index: _this.state.index - 1 });
          break;
        case 13:
          // Enter
          if (_this.state.results[_this.state.index]) {
            _this.handleSelect(_this.state.results[_this.state.index]);
            _this.setState({ query: '', index: -1 });
            break;
          }
        case 37:
          break;
        case 39:
          break;
        default:
          _this.setState({ query: _this.props.input.value });
          _this.fetchResults();
        // if (_this.state.timeout) {
        //   clearTimeout(_this.state.timeout);
        // }
        // const timeout = setTimeout(() => {
        //   _this.setState({ query: _this.props.input.value });
        //   _this.fetchResults();
        // }, 500);
        // _this.setState({ timeout: timeout });
      }
    });

    this.props.input.addEventListener('keydown', function (e) {
      if (e.keyCode == 13) e.preventDefault();
    });
  }

  componentWillUnmount() {}

  UNSAFE_componentWillUpdate() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.state.query == '') {
      $(this.props.input).closest('.whisper-wrapper').removeClass('opened');
    } else {
      $(this.props.input).closest('.whisper-wrapper').addClass('opened');
    }

    // Load mapy.cz
    if (!window.mapyLoaded) {
      try {
        // Loader.async = true;
        // Loader.load(null, null, createMap);
        Loader.load();
        window.mapyLoaded = true;
      } catch (error) {
        window.mapyLoaded = false;
      }
    }
  }

  handleSelect(result, e) {
    if (e) e.preventDefault();
    let _this = this;
    let row;
    this.setState({ query: '', index: -1 });
    var reactComponent =
      window.contractForm || window.customerForm || window.userForm;

    switch (this.props.scenario) {
      case 'google-address':
        let geocoder = new google.maps.Geocoder();
        let googleLatLng = {
          lat: result.coords.y,
          lng: result.coords.x,
        };
        geocoder.geocode(
          { location: googleLatLng },
          function (results, status) {
            if (results && results.length) {
              let source = results[0];
              let postal_code =
                source.address_components.filter((i) =>
                  i.types.includes('postal_code')
                )[0] || {};
              let city =
                source.address_components.find((i) => {
                  return i.types.includes('locality');
                }) ||
                source.address_components.find((i) => {
                  return i.types.includes('sublocality');
                }) ||
                source.address_components.find((i) => {
                  return (
                    i.types.includes('political') &&
                    !i.types.includes('neighborhood')
                  );
                }) ||
                {};

              if (city.long_name)
                city.long_name = city.long_name.replace(
                  'Hlavní město Praha',
                  'Praha'
                );

              if (reactComponent) {
                if (window.userForm) {
                  reactComponent.setState({
                    user: Object.assign({}, reactComponent.state.user, {
                      zip: postal_code.short_name,
                      city: city.long_name,
                    }),
                  });
                } else {
                  let uuid =
                    reactComponent.state.activePlaceUUID ||
                    reactComponent.state.contract.place_uuid;
                  let places = reactComponent.state.customer.places.map((i) => {
                    if (i.uuid == uuid) {
                      return Object.assign(i, {
                        zip: postal_code.short_name,
                        city: city.long_name,
                      });
                    } else {
                      return i;
                    }
                  });
                  reactComponent.setState({
                    customer: Object.assign(reactComponent.state.customer, {
                      places: places,
                    }),
                  });
                }
              } else {
                let wrapper = $(_this.props.input).closest('.address-wrapper');
                wrapper.find('input.zip').val(postal_code.short_name);
                wrapper.find('input.city').val(city.long_name);
              }
            }
          }
        );

        // if (reactComponent) {
        //   if (window.userForm) {
        //     reactComponent.setState({
        //       user: Object.assign({}, reactComponent.state.user, {
        //         latitude: result.coords.y,
        //         longitude: result.coords.x,
        //         street: result.label.split(',')[0],
        //         city: result.label.split(',')[1],
        //         zip: '',
        //       }),
        //     });
        //   } else {
        //     let uuid =
        //       reactComponent.state.activePlaceUUID ||
        //       reactComponent.state.contract.place_uuid;
        //     let places = reactComponent.state.customer.places.map((i) => {
        //       if (i.uuid == uuid) {
        //         return Object.assign(i, {
        //           latitude: result.coords.y,
        //           longitude: result.coords.x,
        //           street: result.label.split(',')[0],
        //           city: result.label.split(',')[1],
        //           zip: '',
        //         });
        //       } else {
        //         return i;
        //       }
        //     });
        //     reactComponent.setState({
        //       latitude: result.coords.y,
        //       longitude: result.coords.x,
        //       customer: Object.assign(reactComponent.state.customer, {
        //         places: places,
        //       }),
        //     });
        //   }
        // } else {
        //   let wrapper = $(this.props.input).closest('.address-wrapper');
        //   wrapper.find('input.latitude').val(result.coords.y);
        //   wrapper.find('input.longitude').val(result.coords.x);
        //   wrapper.find('input.street').val(result.label.split(',')[0]);
        //   wrapper.find('input.city').val(result.label.split(',')[1]);
        //   wrapper.find('input.zip').val('');
        // }

        // Update map
        if (result) {
          let mapIndex =
            this.props.input.closest('.address-wrapper').dataset.map;
          let map = window.googleMaps.find((i) => i.index == mapIndex).map;
          var latLng = { lat: result.coords.y, lng: result.coords.x };
          window.addMarker(latLng, mapIndex);
          map.setCenter(latLng);
          map.setZoom(12);
        }

        break;
      case 'address':
        break;
    }

    if (reactComponent) {
      if (window.userForm) {
        reactComponent.setState({
          user: Object.assign({}, reactComponent.state.user, {
            latitude: result.data.position.lat,
            longitude: result.data.position.lon,
            street: result.data.name,
            city: result.data.location.split(',')[0],
            zip: result.data.zip,
          }),
        });
      } else {
        let uuid =
          reactComponent.state.activePlaceUUID ||
          reactComponent.state.contract.place_uuid;
        let places = reactComponent.state.customer.places.map((i) => {
          if (i.uuid == uuid) {
            return Object.assign(i, {
              latitude: result.data.position.lat,
              longitude: result.data.position.lon,
              street: result.data.name,
              city: result.data.location.split(',')[0],
              zip: result.data.zip,
            });
          } else {
            return i;
          }
        });
        reactComponent.setState({
          latitude: result.data.position.lat,
          longitude: result.data.position.lon,
          customer: Object.assign(reactComponent.state.customer, {
            places: places,
          }),
        });
      }
    } else if (this.props.onChange) {
      this.props.onChange({
        latitude: result.data.position.lat,
        longitude: result.data.position.lon,
        street: result.data.name,
        city: result.data.location.split(',')[0],
        zip: result.data.zip,
      });
    } else {
      let wrapper = $(this.props.input).closest('.address-wrapper');
      wrapper.find('input.latitude').val(result.data.position.lat);
      wrapper.find('input.longitude').val(result.data.position.long);
      wrapper.find('input.street').val(result.data.name);
      wrapper.find('input.city').val(rresult.data.location.split(',')[0]);
      wrapper.find('input.zip').val('');
    }
  }

  // fetchResults() {
  //   let _this = this
  //   if (this.state.query.length > 2) {
  //     if (this.currentRequest) clearTimeout(this.currentRequest)
  //     _this.setState({loading: true})
  //     this.currentRequest = setTimeout(function() {
  //       if (typeof SMap === 'undefined') return

  //       let geocode = new SMap.Geocoder(_this.state.query, function(geocoder) {
  //         let results = geocoder.getResults()[0].results
  //         // console.log(results)
  //         _this.setState({
  //           results: results || [],
  //           loading: false
  //         })
  //       })
  //     }, 500)
  //   }
  // }

  fetchResults() {
    const _this = this;

    if (this.state.query.length > 2) {
      if (this.currentRequest) clearTimeout(this.currentRequest);

      _this.setState({ loading: true });

      this.currentRequest = setTimeout(async function () {
        try {
          const query = _this.state.query;
          const API_KEY = MAPY_API_KEY; // Replace with your actual API key
          const response = await fetch(
            `https://api.mapy.cz/v1/suggest?lang=cs&limit=5&type=regional.address&apikey=${API_KEY}&query=${query}`
          );
          const jsonData = await response.json();
          const results = jsonData.items.map((item) => ({
            id: item.name,
            label: [item.name, item.location].join(', '),
            data: item,
          }));

          _this.setState({
            results: results || [],
            loading: false,
          });
        } catch (error) {
          console.error('Error fetching data:', error);
          _this.setState({ loading: false });
        }
      }, 500);
    }
  }

  render() {
    let results = this.state.results;

    if (
      (this.props.scenario == 'react_inventory_records' ||
        this.props.scenario == 'react_inventory_records_products') &&
      $.react_inventory_records
    ) {
      results = results.filter(
        (i) =>
          !$.react_inventory_records.state.records.find((ii) => ii.id == i.id)
      );
      results = results.filter(
        (i) =>
          !$.react_inventory_records.state.products.find((ii) => ii.id == i.id)
      );
    }
    return (
      <React.Fragment>
        <div
          className={classNames('loading', { 'd-none': !this.state.loading })}
        >
          <div className='spinner-grow' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
        <div
          className={classNames('whisper', {
            'd-none': !(
              this.state.query.length > 2 && this.state.results.length > 0
            ),
          })}
        >
          <table className='results-table'>
            <tbody className='results'>
              {results.length == 0 ? (
                <tr>
                  <td colSpan='4' className='text-center'>
                    <em>Nenalazeno</em>
                  </td>
                </tr>
              ) : (
                results.map((i, index) => {
                  return (
                    <tr
                      key={i.id}
                      onClick={this.handleSelect.bind(this, i)}
                      className={classNames('result', {
                        focused: index == this.state.index,
                      })}
                    >
                      <td>{i.label}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default Whisper;
