import Helper from '../helpers/helper'
import { QRCodeSVG } from 'qrcode.react'

function QRPayment(props) {
  let company
  if (props.master) {
    company = props.master.state.companies.find(i => i.id == props.contract.company_id)
  } else {
    company = JSON.parse(document.body.dataset.companies).find(i => i.id == props.contract.company_id)
  }
  if (!company) return

  let toPay = 0.0
  if (props.toPay) {
    toPay = props.toPay
  } else {
    toPay = props.contract.to_pay

    if (props.contract.partial_payment) {
      toPay -= props.contract.partial_payment_value
    }
  }

  toPay = Math.round(toPay * 100) / 100

  let value = [
    'SPD',
    '1.0',
    `ACC:${company.iban}`,
    `AM:${toPay}`,
    `CC:CZK`,
    `MSG:${props.contract.id}`,
    `RF:${props.contract.id}`,
    `DT:${moment().format('YYYYMMDD')}`,
    `X-VS:${props.contract.id}`
  ].join('*')

  if (props.master && props.master.state.contractsToSync?.find(i => i.contract.id == props.contract.id)) {
    return (
      <h5 className="text-center mt-3">
        QR platbu nelze zobrazit jelikož zakázka čeká na synchronizaci.
        <br/>
        QR platbu můžete ale zobrazit v editaci zakázky přes Upravit.
      </h5>
    )
  }

  return (
    <>
      <div className="qr-payment">
        <QRCodeSVG
          size="160"
          value={value} />
      </div>
      <h4 className="text-center">
        QR platba {Helper.formatMoney(toPay)} Kč
      </h4>
    </>
  );
}

export default QRPayment
