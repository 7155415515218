import CommonAsyncSelect from './common_async_select'
import ReactSelect from 'react-select'
import Select from './select'
import Input from './input';

function ConsultationJobs({ contract, handleInputChange, master, accessToken }) {

  let diameterOptions = ['', '1', '1/2','3/4','5/4','6/4','2'].map(i => { return {value: i, label: i}} )
  let manufacturerOptions
  if (master) {
    manufacturerOptions = master.state.manufacturers.map(i => {
      return {label: i.name, value: i.name}
    })
  }

  return (
    <div className={classNames('card bg-light mb-4')}>
      <div className="card-body">
          <strong>{I18n.t(`consultation_jobs.current_device`)}</strong>
          <div className="row">
            <div className="col-sm-6">
            <label htmlFor="">{I18n.t(`consultation_jobs.current_device_manufacturer`)}</label>
              { manufacturerOptions ? (
                <ReactSelect
                  name="current_device_manufacturer"
                  value={contract.consultation_jobs.current_device_manufacturer ? {value: contract.consultation_jobs.current_device_manufacturer, label: contract.consultation_jobs.current_device_manufacturer} : null}
                  onChange={handleInputChange}
                  placeholder="Hledat"
                  options={manufacturerOptions}
                />
              ) : (
                <CommonAsyncSelect
                  name="current_device_manufacturer"
                  value={contract.consultation_jobs.current_device_manufacturer ? {value: contract.consultation_jobs.current_device_manufacturer, label: contract.consultation_jobs.current_device_manufacturer} : null}
                  url="/api/v1/manufacturers"
                  type="manufacturer"
                  errors={[]}
                  accessToken={accessToken}
                  handleReactSelectChange={handleInputChange}
                  />
              )}
            </div>
            <div className="col-sm-6">
              <Input
                name="current_device_type"
                type="text"
                object={contract.consultation_jobs}
                label={I18n.t(`consultation_jobs.current_device_type`)}
                onChange={handleInputChange} />
            </div>
          </div>
          

        <Input
          name="current_device_place"
          type="text"
          object={contract.consultation_jobs}
          label={I18n.t(`consultation_jobs.current_device_place`)}
          onChange={handleInputChange} />

        <strong>{I18n.t(`consultation_jobs.planned_device`)}</strong>
        <div className="row">
          <div className="col-sm-6">
            <label htmlFor="">{I18n.t(`consultation_jobs.planned_device_manufacturer`)}</label>
            { manufacturerOptions ? (
              <ReactSelect
                name="planned_device_manufacturer"
                value={contract.consultation_jobs.current_device_manufacturer ? {value: contract.consultation_jobs.current_device_manufacturer, label: contract.consultation_jobs.current_device_manufacturer} : null}
                onChange={handleInputChange}
                placeholder="Hledat"
                options={manufacturerOptions}
              />
            ) : (
              <CommonAsyncSelect
                name="planned_device_manufacturer"
                value={contract.consultation_jobs.current_device_manufacturer ? {value: contract.consultation_jobs.current_device_manufacturer, label: contract.consultation_jobs.current_device_manufacturer} : null}
                url="/api/v1/manufacturers"
                type="manufacturer"
                errors={[]}
                accessToken={accessToken}
                handleReactSelectChange={handleInputChange}
                />
            )}
          </div>
          <div className="col-sm-6">
            <Input
              name="planned_device_type"
              type="text"
              object={contract.consultation_jobs}
              label={I18n.t(`consultation_jobs.planned_device_type`)}
              onChange={handleInputChange} />
          </div>
        </div>

        <Input
          name="regulation"
          type="checkbox"
          object={contract.consultation_jobs}
          label={I18n.t(`consultation_jobs.regulation`)}
          onChange={handleInputChange} />

        <Input
          name="equithermal_sensor"
          type="checkbox"
          object={contract.consultation_jobs}
          label={I18n.t(`consultation_jobs.equithermal_sensor`)}
          onChange={handleInputChange} />

        {['water', 'gas', 'heat', 'waste'].map(i => (
          <div className="row" key={i}>
            <div className="col">
              <Select
                name={`${i}_diameter`}
                type="number"
                append='“'
                object={contract.consultation_jobs}
                label={I18n.t(`consultation_jobs.${i}_diameter`)}
                options={diameterOptions}
                onChange={handleInputChange} />
            </div>
            <div className="col">
              <Input
                name={`${i}_material`}
                type="text"
                object={contract.consultation_jobs}
                label={I18n.t(`consultation_jobs.${i}_material`)}
                onChange={handleInputChange} />
            </div>
          </div>
        ))}

        <Input
          name="condensate_pump"
          type="checkbox"
          object={contract.consultation_jobs}
          label={I18n.t(`consultation_jobs.condensate_pump`)}
          onChange={handleInputChange} />

        {['heat', 'hygienic'].map(i => (
          <React.Fragment key={i}>
            <Input
              name={`${i}_external_expanse`}
              type="checkbox"
              object={contract.consultation_jobs}
              label={I18n.t(`consultation_jobs.${i}_external_expanse`)}
              onChange={handleInputChange} />

            {contract.consultation_jobs[`${i}_external_expanse`] && (
              <Input
                name={`${i}_external_expanse_size`}
                type="number"
                append="l"
                object={contract.consultation_jobs}
                label={I18n.t(`consultation_jobs.${i}_external_expanse_size`)}
                onChange={handleInputChange} />
            )}
          </React.Fragment>
        ))}

        <Select
          name="magnetic_filter"
          object={contract.consultation_jobs}
          label={I18n.t(`consultation_jobs.magnetic_filter`)}
          onChange={handleInputChange}
          defaultValue=''
          options={[
            { value: '', label: '' },
            { value: 'Malý', label: 'Malý' },
            { value: 'Velký', label: 'Velký' },
          ]} />       

        <Input
          name="electro_job"
          type="checkbox"
          object={contract.consultation_jobs}
          label={I18n.t(`consultation_jobs.electro_job`)}
          onChange={handleInputChange} />

        {contract.consultation_jobs.electro_job && (
          <Input
            name="electro_job_description"
            type="text"
            object={contract.consultation_jobs}
            label={I18n.t(`consultation_jobs.electro_job_description`)}
            onChange={handleInputChange} />
        )}

        <Input
          name="old_device_liquidation"
          type="checkbox"
          object={contract.consultation_jobs}
          label={I18n.t(`consultation_jobs.old_device_liquidation`)}
          onChange={handleInputChange} />

        {contract.consultation_jobs.old_device_liquidation && (
          <div className="row">
            <div className="col">
              <Input
                name="floor"
                type="text"
                object={contract.consultation_jobs}
                label={I18n.t(`consultation_jobs.floor`)}
                onChange={handleInputChange} />
            </div>
            <div className="col" style={{paddingTop: '38px'}}>
              <Input
                name="lift"
                type="checkbox"
                object={contract.consultation_jobs}
                label={I18n.t(`consultation_jobs.lift`)}
                onChange={handleInputChange} />
            </div>
          </div>
        )}

        <Input
          name="help"
          type="checkbox"
          object={contract.consultation_jobs}
          label={I18n.t(`consultation_jobs.help`)}
          onChange={handleInputChange} />

        {contract.consultation_jobs.help && (
          <Input
            name="help_description"
            type="text"
            object={contract.consultation_jobs}
            label={I18n.t(`consultation_jobs.help_description`)}
            onChange={handleInputChange} />
        )}

      </div>
    </div>
  );
}

export default ConsultationJobs
