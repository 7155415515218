import { renderComponent } from '../helpers/shared_functions';

import Helper from '../helpers/helper';
import Loading from './loading';
import Modal from './modal';
import ContractModalBody from './contract_modal_body';
import DocumentList from './document_list';
import ContractList from './contract_list';
import Comments from './comments';
import Device from './device';
import DeviceImages from './device_images';
import ShortMessages from './short_messages/short_messages';
import { render } from 'sass';

class CustomerModalBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: props.tab || 'basic',
      contracts: [],
    };
  }

  componentDidMount() {
    this.fetchContracts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.customer.id != this.props.customer.id) {
      this.fetchContracts();
    }

    if (prevProps.tab && prevProps.tab != this.props.tab) {
      this.setState({ tab: this.props.tab });
    }
  }

  fetchContracts() {
    const currentRequest = jQuery.ajax({
      method: 'GET',
      url: '/api/v1/contracts',
      data: {
        customer_id: this.props.customer.id,
        parent_id: this.props.customer.id,
      },
      beforeSend: (xhr) => {
        if (this.props.accessToken) {
          xhr.setRequestHeader(
            'Authorization',
            'Bearer ' + this.props.accessToken.access_token
          );
        } else {
          xhr.setRequestHeader(
            'Authorization',
            'Bearer ' + document.body.dataset.jwt
          );
        }

        if (this.currentRequest) {
          this.currentRequest.abort();
        }
        this.setState({ loading: true });
        this.currentRequest = null;
      },
      success: (data) => {
        this.setState({ contracts: data.results });
      },
      complete: () => {
        this.setState({ loading: false });
        this.currentRequest = null;
      },
    });
    this.currentRequest = currentRequest;
  }

  handleChangeTab(tab, e) {
    e.preventDefault();
    this.setState({ tab: tab });
  }

  showCustomer(customer, tab = 'basic', e) {
    e.preventDefault();
    e.stopPropagation();

    let parentCustomer = this.props.parentCustomer || this.props.customer;

    let modalBody = (
      <CustomerModalBody
        customer={customer}
        master={this.props.master}
        parentCustomer={parentCustomer}
        accessToken={this.props.accessToken}
      />
    );

    this.setState({ tab: tab });

    renderComponent({
      element: document.querySelector('#modal-container'),
      component: Modal,
      props: {
        title:
          parentCustomer && parentCustomer.id != customer.id ? (
            <span>
              <a
                href='#'
                className='btn btn-outline-primary'
                style={{ marginRight: '1rem' }}
                onClick={this.showCustomer.bind(
                  this,
                  parentCustomer,
                  'related_contracts'
                )}
              >
                Zpět na {parentCustomer.id}
              </a>
              {`Detail zákazníka ${customer.id}`}
            </span>
          ) : (
            `Detail zákazníka ${customer.id}`
          ),
        body: modalBody,
        linkTitle: 'Upravit',
        link: `/is/customers/${customer.id}/edit`,
      },
    });
  }

  showContract(contract, tab = 'basic', e) {
    e.preventDefault();
    e.stopPropagation();

    let parentCustomer = this.props.parentCustomer || this.props.customer;

    let modalBody = (
      <ContractModalBody
        contract={contract}
        master={this.props.master}
        parentCustomer={parentCustomer}
        accessToken={this.props.accessToken}
      />
    );

    this.setState({ tab: tab });

    // LinkEvent
    let linkEvent;
    if (this.props.master) {
      if (
        this.props.master.state.user.id == contract.user_id &&
        ((moment(contract.finished_at) >= moment().add(-30, 'minutes') &&
          contract.status == 'finished') ||
          contract.status != 'finished')
      ) {
        linkEvent = (e) => {
          e.preventDefault();
          jQuery('#modal').modal('hide');
          this.props.master.setState({
            page: 'contract-form',
            editContract: contract,
          });
          history.pushState({ page: 'contract-form' }, null, 'contract-form');
        };
      } else {
        linkEvent = null;
      }
    } else {
      linkEvent = (e) => {
        e.preventDefault();
        Turbo.visit(`/is/contracts/${contract.id}/edit`);
      };
    }

    renderComponent({
      element: document.querySelector('#modal-container'),
      component: Modal,
      props: {
        title: (
          <span>
            <a
              href='#'
              className='btn btn-outline-primary'
              style={{ marginRight: '1rem' }}
              onClick={this.showCustomer.bind(
                this,
                parentCustomer,
                'related_contracts'
              )}
            >
              Zpět na zákazníka {this.props.customer.id}
            </a>
            {`Detail ${
              contract.kind == 'reservation' ? 'rezervace' : 'zakázky'
            } ${contract.id}`}
          </span>
        ),
        body: modalBody,
        linkTitle: 'Upravit',
        linkEvent: linkEvent,
        contract: contract,
        master: this.props.master,
      },
    });
  }

  render() {
    let users = this.props.master
      ? this.props.master.state.users
      : window.users;

    return (
      <div className='contract-modal-body'>
        <ul className='nav nav-pills mb-4'>
          <li className='nav-item'>
            <a
              href='#'
              onClick={this.handleChangeTab.bind(this, 'basic')}
              className={classNames('nav-link', {
                active: this.state.tab == 'basic',
              })}
            >
              Základní
            </a>
          </li>
          <li className='nav-item'>
            <a
              href='#'
              onClick={this.handleChangeTab.bind(this, 'places')}
              className={classNames('nav-link', {
                active: this.state.tab == 'places',
              })}
            >
              Místa
            </a>
          </li>
          <li className='nav-item'>
            <a
              href='#'
              onClick={this.handleChangeTab.bind(this, 'related_contracts')}
              className={classNames('nav-link', {
                active: this.state.tab == 'related_contracts',
              })}
            >
              Zakázky
            </a>
          </li>
          <li className='nav-item'>
            <a
              href='#'
              onClick={this.handleChangeTab.bind(this, 'documents')}
              className={classNames('nav-link', {
                active: this.state.tab == 'documents',
              })}
            >
              Doklady
            </a>
          </li>
          <li className='nav-item'>
            <a
              href='#'
              onClick={this.handleChangeTab.bind(this, 'comments')}
              className={classNames('nav-link', {
                active: this.state.tab == 'comments',
              })}
            >
              Komentáře
            </a>
          </li>
          {!this.props.master ? (
            <li className='nav-item'>
              <a
                href='#'
                onClick={this.handleChangeTab.bind(this, 'short_messages')}
                className={classNames('nav-link', {
                  active: this.state.tab == 'short_messages',
                })}
              >
                SMS
              </a>
            </li>
          ) : null}
        </ul>

        <div className={classNames({ 'd-none': this.state.tab != 'basic' })}>
          <div className='row mb-3'>
            <div className='col'>
              <strong>Zákazník</strong>
              <br />
              {this.props.customer.name}
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col'>
              <strong>E-mail</strong>
              <br />
              {this.props.customer.email_status == 'has' ? (
                <table className='table table-sm table-hover contract-detail'>
                  <tbody>
                    {this.props.customer.emails_data.map((i) => (
                      <tr key={i.uuid}>
                        <td>
                          <a href={`mailto:${i.value}`}>{i.value}</a>
                        </td>
                        <td>{i.note}</td>
                        <td>
                          <i
                            className={classNames({
                              'fas fa-check': i.default,
                            })}
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
              {this.props.customer.email_status == 'has_not' ? 'Není' : null}
              {this.props.customer.email_status == 'missing' ? 'Doplnit' : null}
            </div>

            <div
              className={classNames('col', {
                'd-none':
                  this.props.master &&
                  this.props.master.state.user &&
                  !this.props.master.state.user.can_see_phones,
              })}
            >
              <strong>Telefon</strong>
              <br />
              <table className='table table-sm table-hover contract-detail'>
                <tbody>
                  {this.props.customer.phones_data.map((i) => (
                    <tr key={i.uuid}>
                      <td>
                        <a href={`tel:${i.value.replace(/\s/g, '')}`}>
                          {Helper.formatPhone(
                            parseInt(i.value.replace(/\s/g, ''))
                          )}
                        </a>
                      </td>
                      <td>{i.note}</td>
                      <td>
                        <i
                          className={classNames({ 'fas fa-check': i.default })}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col'>
              <strong>Firma</strong>
              <br />
              {this.props.customer.company ? 'Ano' : 'Ne'}
            </div>
            <div className='col'>
              <strong>IČ</strong>
              <br />
              {this.props.customer.has_id_no ? 'Ano' : 'Ne'}
            </div>
            <div className='col'>
              <strong>
                {I18n.t('activerecord.attributes.customer.has_ppas')}
              </strong>
              <br />
              {this.props.customer.places.filter((i) => i.has_ppas).length
                ? 'Ano'
                : 'Ne'}
            </div>
            <div className='col'></div>
          </div>

          <div
            className={classNames('row mb-3', {
              'd-none': !this.props.customer.company,
            })}
          >
            <div className='col'>
              <strong>Kontaktní osoba</strong>
              <br />
              {this.props.customer.person}
            </div>
            <div className='col'>
              <strong>IČ</strong>
              <br />
              {this.props.customer.id_no}
            </div>
            <div className='col'>
              <strong>DIČ</strong>
              <br />
              {this.props.customer.tax_no}
            </div>
          </div>

          <p
            className={classNames({
              'd-none': !this.props.customer.has_billing_address,
            })}
          >
            <strong>Fakturační údaje</strong>
            <br />
            {[
              this.props.customer.billing_name,
              this.props.customer.street,
              this.props.customer.city,
              this.props.customer.zip,
              this.props.customer.billing_id_no,
              this.props.customer.billing_tax_no,
            ]
              .filter((i) => i)
              .join(', ')}
          </p>

          <div className='row mb-3'>
            <div className='col'>
              <strong>{I18n.t('activerecord.attributes.customer.kind')}</strong>
              <br />
              {I18n.t(`customer-kinds.${this.props.customer.kind}`)}
            </div>

            <div
              className={classNames('col', {
                'd-none': this.props.customer.kind != 'problematic',
              })}
            >
              <strong>
                {I18n.t('activerecord.attributes.customer.problematic_note')}
              </strong>
              <br />
              {this.props.customer.problematic_note}
            </div>

            <div
              className={classNames('col', {
                'd-none': this.props.customer.kind != 'new',
              })}
            >
              <strong>
                {I18n.t('activerecord.attributes.customer.new_note')}
              </strong>
              <br />
              {this.props.customer.new_note}
            </div>

            <div className='col'></div>
          </div>

          <div className='row mb-3'>
            <div className='col'>
              <strong>{I18n.t('activerecord.attributes.customer.note')}</strong>
              <br />
              {this.props.customer.note}
            </div>
          </div>
        </div>

        <div className={classNames({ 'd-none': this.state.tab != 'places' })}>
          <div className='places'>
            {this.props.customer.places.map((place) => (
              <div key={place.uuid} className='card bg-light mb-3'>
                <div className='card-body'>
                  <div className='row mb-4'>
                    <div className='col'>
                      <strong>Adresa</strong>
                      <br />
                      {[place.street, place.city, place.zip].join(', ')}
                      {place.has_billing_address ? (
                        <React.Fragment>
                          <br />
                          <strong>Fakturační adresa</strong>
                          <br />
                          {[
                            place.billing_name,
                            place.billing_street,
                            place.billing_city,
                            place.billing_zip,
                          ]
                            .filter((i) => i)
                            .join(', ')}
                        </React.Fragment>
                      ) : null}
                    </div>
                    <div className='col'>
                      <strong>Souřadnice</strong>
                      <br />
                      {[place.latitude, place.longitude].join(', ')}
                      <br />
                      <a
                        href={`https://google.com/maps/search/${[
                          place.latitude,
                          place.longitude,
                        ].join(',')}`}
                        target='_blank'
                        className='btn btn-sm btn-outline-secondary'
                        style={{ marginTop: '0.5rem' }}
                      >
                        Zobrazit na mapě
                      </a>
                    </div>
                    <div className='col'>
                      <strong>PPAS</strong>
                      <br />
                      {place.has_ppas ? 'Ano' : 'Ne'}
                    </div>
                    <div className='col-auto'>
                      <strong>ID</strong>
                      <br />
                      {place.id}
                    </div>
                  </div>

                  <div className='mb-3'>
                    <strong>Umístění</strong>
                    <br />
                    {place.location}
                  </div>

                  <table className='table table-sm table-borderless'>
                    <thead>
                      <tr>
                        <th>Výrobce</th>
                        <th>Typ zařízení</th>
                        <th>RV</th>
                        <th>Poznámka</th>
                        <th>Verze</th>
                        <th>ID</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {(place.devices || []).map((device, index) => (
                        <React.Fragment key={device.uuid}>
                          <tr key={index}>
                            <td>{device.manufacturer}</td>
                            <td>{device.type}</td>
                            <td>{device.year}</td>
                            <td>{device.note}</td>
                            <td>{device.version}</td>
                            <td>{device.uuid.split('-')[0]}</td>
                            <td>
                              {!device.active ? (
                                <strong>Neaktivní</strong>
                              ) : null}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan='7'>
                              <DeviceImages
                                deviceUuid={device.uuid}
                                master={this.props.master}
                              />
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          className={classNames({
            'd-none': this.state.tab != 'related_contracts',
          })}
        >
          {this.state.loading ? <Loading /> : null}

          <ContractList
            loading={this.state.loading}
            contracts={this.state.contracts}
            users={users}
            showContract={this.showContract.bind(this)}
          />
        </div>

        <div
          className={classNames({ 'd-none': this.state.tab != 'documents' })}
        >
          <DocumentList
            documents={this.props.customer.documents}
            pdfView={true}
          />
        </div>

        <div className={classNames({ 'd-none': this.state.tab != 'comments' })}>
          <Comments
            customerId={this.props.customer.id}
            accessToken={this.props.accessToken}
            tab={this.state.tab}
          />
        </div>

        {!this.props.master ? (
          <div
            className={classNames({
              'd-none': this.state.tab != 'short_messages',
            })}
          >
            <ShortMessages
              customerId={this.props.customer.id}
              accessToken={this.props.accessToken}
              phones={this.props.customer.phones_data}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default CustomerModalBody;
