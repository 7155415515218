import Whisper from './whisper';

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.inputRef = React.createRef();
  }

  render() {
    let {
      className,
      object,
      checkboxInvisible,
      whisperScenario,
      ...moreProps
    } = this.props;

    let label;
    if (this.props.label != 'false') {
      label = (
        <label
          className={classNames({
            'form-check-label': this.props.type == 'checkbox',
            'form-control-label': this.props.type != 'checkbox',
          })}
          htmlFor={this.props.id || this.props.name}
        >
          {this.props.label ||
            I18n.t(
              `activerecord.attributes.${this.props.object.type}.${this.props.name}`,
              { defaultValue: I18n.t(`attributes.${this.props.name}`) }
            )}
        </label>
      );
    }

    let input;
    if (this.props.type == 'checkbox') {
      input = (
        <div
          className={classNames('form-check', {
            'is-invalid':
              (this.props.object.errors &&
                this.props.object.errors[this.props.name] &&
                this.props.object.errors[this.props.name].length) ||
              this.props.error,
          })}
        >
          <input
            className={classNames('form-check-input', {
              invisible: checkboxInvisible,
            })}
            checked={this.props.object[this.props.name]}
            id={this.props.id || this.props.name}
            ref={this.inputRef}
            {...moreProps}
          />
          {label}
        </div>
      );
    } else if (this.props.type == 'textarea') {
      input = (
        <React.Fragment>
          {label}
          <textarea
            className={classNames('form-control', this.props.className, {
              'is-invalid':
                this.props.object.errors &&
                this.props.object.errors[this.props.name] &&
                this.props.object.errors[this.props.name].length,
            })}
            id={this.props.id || this.props.name}
            value={this.props.object[this.props.name] || ''}
            ref={this.inputRef}
            {...moreProps}
          />
        </React.Fragment>
      );
    } else if (this.props.append) {
      input = (
        <React.Fragment>
          {label}
          <div className='input-group'>
            <input
              className={classNames('form-control', this.props.className, {
                'is-invalid':
                  this.props.object.errors &&
                  this.props.object.errors[this.props.name] &&
                  this.props.object.errors[this.props.name].length,
              })}
              id={this.props.id || this.props.name}
              value={this.props.object[this.props.name]}
              ref={this.inputRef}
              {...moreProps}
            />
            <div className='input-group-append'>
              <div className='input-group-text'>{this.props.append}</div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      input = (
        <React.Fragment>
          {label}
          <input
            className={classNames('form-control', this.props.className, {
              'is-invalid':
                (this.props.object.errors &&
                  this.props.object.errors[this.props.name] &&
                  this.props.object.errors[this.props.name].length) ||
                this.props.error,
            })}
            id={this.props.id || this.props.name}
            value={this.props.object[this.props.name] || ''}
            ref={this.inputRef}
            {...moreProps}
          />
        </React.Fragment>
      );
    }

    return (
      <div className='form-group'>
        <div
          className={classNames({
            'whisper-wrapper': this.props.whisperScenario,
          })}
        >
          {input}
          {this.props.whisperScenario && this.inputRef.current && (
            <div className='whisper-container'>
              <Whisper
                input={this.inputRef.current}
                scenario={this.props.whisperScenario}
                onChange={this.props.legacy ? this.props.onChange : undefined}
              />
            </div>
          )}
        </div>

        {this.props.hint ? (
          <small className='form-text text-muted'>{this.props.hint}</small>
        ) : null}

        <div className='invalid-feedback'>
          {this.props.object.errors &&
          this.props.object.errors[this.props.name] &&
          this.props.object.errors[this.props.name].length
            ? this.props.object.errors[this.props.name][0]
            : this.props.error}
        </div>
      </div>
    );
  }
}

export default Input;
