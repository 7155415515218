import { useState, useRef, useEffect } from 'react';

import MapSelector from './map_selector';
import Input from './input';

function UserAddress({ user, handleInputChange = undefined, legacy = false }) {
  const [userHelper, setUserHelper] = useState(Object.assign({}, user));

  if (!handleInputChange) {
    handleInputChange = (e) => {
      if (e.target) {
        let o = Object.assign({}, userHelper);
        o[e.target.name] = e.target.value;
        setUserHelper(o);
      } else {
        setUserHelper(Object.assign({}, userHelper, e));
      }
    };

    user = Object.assign({}, user, userHelper);
  }

  const attributes = ['street', 'zip', 'city', 'latitude', 'longitude'];

  return (
    <div className='address-wrapper mb-3'>
      <div className='row'>
        <div className='col-sm-6'>
          <Input
            name='street'
            type='text'
            object={user}
            onChange={handleInputChange}
            autoComplete='new-password'
            data-google-autocomplete='true'
            whisperScenario='address'
            legacy={legacy.toString()}
          />
        </div>

        <div className='col-sm-2'>
          <Input
            name='zip'
            type='text'
            object={user}
            onChange={handleInputChange}
            autoComplete='new-password'
          />
        </div>

        <div className='col-sm-4'>
          <Input
            name='city'
            type='text'
            object={user}
            onChange={handleInputChange}
            autoComplete='new-password'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-6'>
          <Input
            name='latitude'
            type='number'
            object={user}
            onChange={handleInputChange}
            autoComplete='new-password'
          />
        </div>

        <div className='col-sm-6'>
          <Input
            name='longitude'
            type='number'
            object={user}
            onChange={handleInputChange}
            autoComplete='new-password'
          />
        </div>
      </div>

      <MapSelector
        latitude={user.latitude}
        longitude={user.longitude}
        handleInputChange={legacy ? handleInputChange : undefined}
      />

      {legacy &&
        attributes.map((attr) => (
          <input key={attr} type='hidden' name={`user[${attr}]`} value={userHelper[attr]} />
        ))}
    </div>
  );
}

export default UserAddress;
