window.initGoogleMaps = function() {

  if (typeof google !== 'undefined') {
    var inputs = document.querySelectorAll('[data-google-autocomplete]')
    inputs.forEach(input => {

      // Disable enter submit
      input.addEventListener('keydown', function(e) {
        if (e.keyCode == 13) {
          e.preventDefault()
        }
      })

      var autocomplete = new google.maps.places.Autocomplete(
          input,
          {
            types: ['geocode']
            // componentRestrictions: {country: 'cz'}
          }
        );

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      autocomplete.setFields(['address_component','geometry']);

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      autocomplete.addListener('place_changed', fillInAutocompleteAddress.bind(undefined, input, autocomplete));
    })


    // Map
    if (document.querySelectorAll('.google-map').length || document.querySelectorAll('.map-selector').length) initialize()
  }

}

function fillInAutocompleteAddress(input, autocomplete, e) {
  let mapIndex = input.closest('.address-wrapper').dataset.map
  let map = window.googleMaps.find(i => i.index == mapIndex).map
  var place = autocomplete.getPlace();
  if (place) {
    fillInAddress(place, input)
    var latLng = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }
    addMarker(latLng, mapIndex)
    map.setCenter(latLng)
    map.setZoom(12)
  }
}

function fillInAddress(place, input) {
  if (input) {
    var wrapper = $(input).closest('.address-wrapper')
  } else {
    var wrapper = $('.address-wrapper')
  }

  var street, route, premise, street_number, zip, city

  // console.log(place)

  route = place.address_components.find(i => { return i.types.includes('route')}) || place.address_components[0]
  premise = place.address_components.find(i => { return i.types.includes('premise')}) || {}
  street_number = place.address_components.find(i => { return i.types.includes('street_number')}) || {}
  zip = place.address_components.find(i => { return i.types.includes('postal_code')}) || {}
  city = place.address_components.find(i => { return i.types.includes('locality')}) ||
         place.address_components.find(i => { return i.types.includes('sublocality')}) ||
         place.address_components.find(i => {
          return i.types.includes('political') && !i.types.includes('neighborhood')
         }) ||
         {}
  if (city.long_name) city.long_name = city.long_name.replace('Hlavní město Praha', 'Praha')

  var street = [], helper = []

  if (premise.long_name) helper.push(premise.long_name)
  if (street_number.long_name) helper.push(street_number.long_name)
  helper = helper.join('/')

  if (route.long_name) street.push(route.long_name)
  if (helper) street.push(helper)

  street = street.join(' ')

  var reactComponent = window.contractForm || window.customerForm || window.userForm
  if (reactComponent) {
    if (window.userForm) {
      reactComponent.setState({
        user: Object.assign({}, reactComponent.state.user, {
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
          street: street,
          zip: zip.long_name,
          city: city.long_name
        }
      )})
    } else {
      let uuid = reactComponent.state.activePlaceUUID || reactComponent.state.contract.place_uuid
      let places = reactComponent.state.customer.places.map(i => {
        if (i.uuid == uuid) {
          return Object.assign(i, {
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
            street: street,
            zip: zip.long_name,
            city: city.long_name
          })
        } else {
          return i
        }
      })
      reactComponent.setState({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        customer: Object.assign(reactComponent.state.customer, {places: places})
      })
    }

  } else {
    wrapper.find('input.latitude').val(place.geometry.location.lat())
    wrapper.find('input.longitude').val(place.geometry.location.lng())
    wrapper.find('input.street').val(street)
    wrapper.find('input.zip').val(zip.long_name)
    wrapper.find('input.city').val(city.long_name)
  }

}

function initialize() {
  window.googleMaps = []
  var intialLatLng = { lat: 49.793316, lng: 15.430921 };
  document.querySelectorAll('.google-map').forEach((mapEl, index) => {
    var initMarker = false

    if (mapEl.dataset.latitude) {
      initMarker = true
      intialLatLng = {
        lat: parseFloat(mapEl.dataset.latitude),
        lng: parseFloat(mapEl.dataset.longitude)
      };
    }

    let map = new google.maps.Map(mapEl, {
      zoom: (initMarker ? 12 : 7),
      center: intialLatLng
    });

    mapEl.closest('.address-wrapper').dataset.map = index
    window.googleMaps.push({index: index, map: map})

    // This event listener calls addMarker() when the map is clicked.
    google.maps.event.addListener(map, 'click', function(event) {
      addMarker(event.latLng, index);

      var service = new google.maps.places.PlacesService(map);
      var geocoder = new google.maps.Geocoder();

      geocoder.geocode({
        'latLng': event.latLng
      }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[1]) {
            fillInAddress(results[1])
          } else {
            alert('No results found');
          }
        } else {
          alert('Geocoder failed due to: ' + status);
        }
      });
    });

    // Add a marker at the center of the map.
    if (initMarker) addMarker(intialLatLng, index);
  })
}

// Adds a marker to the map.
function addMarker(location, index) {
  let map = window.googleMaps.find(i => i.index == index)
  // Add the marker at the clicked location, and add the next-available label
  // from the array of alphabetical characters.
  if (map.marker) map.marker.setMap(null)

  let marker = new google.maps.Marker({
    position: location,
    map: map.map
  });

  map.marker = marker
}

window.addMarker = addMarker
